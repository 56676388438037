import _ from 'lodash'

import { allModels } from '@/models'

const modelEntityByName = (name) => {
  const map = _.reduce(allModels, (acc, model, _name) => {
    acc[model.class_name] = model.entity
    return acc
  }, {})

  return map[name]
}

export {
  modelEntityByName,
}
