<template lang='pug'>

ion-page
  ion-header
    ion-toolbar
      ion-buttons(slot='start')
        ion-back-button(text='' defaultHref='/')
      ion-buttons(slot='end')
        NavIconButton(
          v-if='false'
          icon='add-circle-outline'
          path='/circles/new'
        )
      ion-title.ion-text-capitalize
        | {{ $t('ui.pages.circles.title') }}

  ion-content
    ion-refresher(
      slot='fixed'
      @ion-refresh='doRefresh($event)'
    )
      ion-refresher-content
    ion-fab(
      slot='fixed'
      vertical='bottom'
      horizontal='end'
    )
      ion-fab-button(
        color='primary'
        @click='$router.push("/circles/new")'
      )
        ion-icon(
          :icon='add'
        )

    EmptyState(
      v-if='circles.length === 0'
      name='Circles'
    )
    van-swipe-cell(
      v-for='circle in circles'
      :key='circle.id'
    )
      ion-item
        ion-label
          | {{ circle.name }}
        ion-chip(slot='end')
          | {{ circle.category?.nameLocalized }}
      template(#left)
        van-button(
          square
          type='primary'
          :text='$t("ui.actions.edit")'
          @click='$router.push(`/circles/${circle.id}/edit`)'
        )
      template(#right)
        van-button(
          square
          type='danger'
          :text='$t("ui.actions.delete")'
          @click='doDelete(circle)'
        )

</template>

<script setup>

import { add } from 'ionicons/icons'

import { getRepo } from '@/repos'
import { destroyToRepo } from '@/logic/models/saveToRepo'

const circleRepo = getRepo('circles')
const circles = computed(() => circleRepo.withAll().get())

const doDelete = (circle) => {
  destroyToRepo(circleRepo, circle.id)
}

const doRefresh = (event) => {
  circleRepo.fresh([])
  circleRepo.api().readAll()
    .then(()=> event.target.complete())
}

</script>

<style scoped lang='stylus'>

.van-swipe-cell
  .van-button
    height: 100%

ion-fab
  margin: 1rem

</style>
