const confirmToRemove = () => {
  return showConfirmDialog({
    title: 'Confirm',
    message: 'Are you sure you want to remove?',
    confirmButtonText: 'Remove',
    cancelButtonText: 'Cancel',
    confirmButtonColor: 'var(--ion-color-danger)',
    closeOnClickOverlay: true,
  })
}


export {
  confirmToRemove,
}
