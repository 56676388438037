<template lang='pug'>

ion-page
  ion-header
    ion-toolbar
      ion-buttons(slot='start')
        ion-back-button(text='' defaultHref='/')
      ion-buttons(slot='end')
        NavTopMyProfileButton
      ion-title
        | Fake Person
  ion-content
    FakePhotoImage(
      :id='fakeId'
      @click='randomizePhoto'
    )

</template>

<script setup>

import FakePhoto from '@/logic/utils/FakePhoto'

const fakePhoto = new FakePhoto

const randomizePhoto = () => {
  fakeId.value = fakePhoto.randomSlug()
}

const fakeId = ref(null)

randomizePhoto()

</script>
