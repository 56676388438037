<template lang='pug'>

PersonPhoto(
  :id='personId'
)

</template>

<script setup>

import { TargetRepo } from '@/repos'

const props = defineProps({
  id: {
    type: Number,
  },
})

const { id } = toRefs(props)

const targetRepo = useRepo(TargetRepo)

const personId = computed(() => {
  return targetRepo.where('id', id.value).withAll().first()?.targetable?.id
})

</script>
