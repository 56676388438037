import {
  formatDistance,
  format,
  isToday,
  isYesterday,
  parseISO,
} from 'date-fns'
import { i18n, t } from '@/logic/i18n'
import { enUS, ru } from 'date-fns/locale'

const timeLocale = () => {
  return {
    en: enUS,
    ru: ru,
  }[i18n.global.locale] || enUS
}

const timeAgo = (timestamp) => {
  return formatDistance(
    new Date(timestamp),
    new Date(),
    {
      addSuffix: true,
      locale: timeLocale(),
    }
  )
}

const recentDate = (timestamp) => {
  const date = parseISO(timestamp)
  const computedRecentDate = computed(() => {
    if (isToday(date)) {
      return t('time.today')
    } else if (isYesterday(date)) {
      return t('time.yesterday')
    } else {
      return format(date, 'MMMM d', { locale: timeLocale() })
    }
  })
  return computedRecentDate
}

export {
  timeAgo,
  recentDate,
}
