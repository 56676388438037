<template lang='pug'>

van-badge(
  :content='counter'
  :max='99'
  color='var(--ion-color-primary)'
)
  .child
    | {{ emoji }}

</template>

<script setup>

import { getRepo } from '@/repos'

const props = defineProps({
  symbol: {
    type: String,
    default: 'none',
  },
  counter: {
    type: [String, Number],
    coerce: Number,
    required: true,
  },
})

const { symbol, counter } = toRefs(props)

const emotionRepo = getRepo('emotions')

const emotion = computed(() => {
  return emotionRepo.where('symbol', symbol.value).first()
})

const emoji = computed(() => {
  return emotion.value?.emoji || ''
})

</script>

<style scoped lang='stylus'>

.child
  padding: 0.125rem
  font-size: 1.5rem

</style>
