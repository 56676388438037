// NOTE: from: https://github.com/ozgrozer/100k-faces/blob/master/glitch/server.js

export default class FakePhoto {

  constructor() {
    this.baseUrl = 'https://ozgrozer.github.io/100k-faces/'
  }

  randomInt(min, max) {
    return Math.floor(Math.random() * (max - min + 1) + min)
  }

  strPad(str) {
    return '000'.slice(str.toString().length) + str
  }

  urlByIds(id1, id2) {
    const folder1 = '0'
    const folder2 = id1
    const file = this.strPad(id2)
    const filename = `00${folder2}${file}`
    const url = `${this.baseUrl}${folder1}/${folder2}/${filename}.jpg`

    return url
  }

  randomId() {
    const id1 = this.randomInt(0, 9)
    const id2 = this.randomInt(0, 999)

    return [id1, id2]
  }

  randomSlug() {
    const [id1, id2] = this.randomId()
    return `${id1}-${id2}`
  }

  randomUrl() {
    return this.urlByIds(...this.randomId())
  }

  urlBySlug(slug) {
    const [id1, id2] = slug.split('-')

    return this.urlByIds(id1, id2)
  }

  url(id) {
    if (id instanceof Array) {
      return this.urlByIds(id[0], id[1])
    }
    if (typeof id == 'string' && id.includes('-')) {
      return this.urlBySlug(id)
    }
    if (id === undefined || id === '') {
      return this.randomUrl()
    }
  }

}
