const alertParamsForAxiosError = (axiosError) => {
  const params = {
    header: undefined,
    subHeader: undefined,
    message: undefined,
    buttons: ['OK'],
  }

  if (axiosError.name != 'AxiosError') return params

  params.header = 'Error'
  const responseData = axiosError.response?.data
  if (responseData?.error) {
    params.message = responseData.error
  } else if(responseData) {
    params.message = JSON.stringify(responseData)
  } else {
    params.message = axiosError.message
  }

  return params
}

export {
  alertParamsForAxiosError,
}
