import { Person } from '@/models'

export default class PersonalThoughtsSummary extends Model {
  static class_name = 'PersonalThoughtsSummary'
  static entity = 'personal_thoughts_summaries'

  static fields() {
    return {
      id: this.uid(),
      created_at_date: this.string(''),
      person_id: this.number(null),
      thoughts_count: this.number(0),
      emotions_count: this.attr({}),
      person: this.belongsTo(Person, 'person_id'),
    }
  }

  static casts() {
    return {
      created_at_date: StringCast,
      person_id: NumberCast,
      thoughts_count: NumberCast,
    }
  }

}
