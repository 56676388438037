import * as yup from 'yup'

const PersonModelSchema =
  yup.object({
    id: yup.string().nullable(),
    name: yup.string().required(),
    slug: yup.string().nullable(),
  })

export {
  PersonModelSchema,
}

