import * as Sentry from '@sentry/vue'

import { env } from '@/env'

const sentryOptions = ({ router }) => {
  return {
    enabled: env.APP_ENV === 'production',
    dsn: env.SENTRY_DSN,
    environment: env.APP_ENV,
    integrations: [
      Sentry.browserTracingIntegration({
        router,
      }),
      Sentry.replayIntegration(),
    ],
    tracesSampleRate: 1.0,
    tracePropagationTargets: [
      'localhost',
      new RegExp(`^${env.APP_URL}`),
      new RegExp(`^${env.API_URL}`),
    ],
    replaysSessionSampleRate: 0.0,
    replaysOnErrorSampleRate: 0.0,
    trackComponents: true,
  }
}

const sentryInit = ({ app, router }) => {
  Sentry.init({
    app,
    ...sentryOptions({ router }),
  });
}

export { sentryInit }
