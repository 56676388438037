import _ from 'lodash'

import { requestForRoute } from '@/logic/api/requestForRoute'
import { Person, CircleTarget } from '@/models'
import { modelEntityByName } from '@/logic/models/modelEntityByName'
import { getRepo } from '@/repos'

export default class Target extends Model {
  static class_name = 'Target'
  static entity = 'targets'

  static fields() {
    return {
      id: this.uid(),
      source_id: this.number(null),
      targetable_type: this.string(null),
      targetable_id: this.number(null),
      name: this.string(null),
      created_at: this.attr(null),
      updated_at: this.attr(null),
      source: this.belongsTo(Person, 'source_id'),
      targetable: this.morphTo([Person], 'targetable_id', 'targetable_type_entity'),
      circle_targets: this.hasMany(CircleTarget, 'target_id'),
    }
  }

  static casts() {
    return {
      source_id: NumberCast,
      targetable_type: StringCast,
      targetable_id: NumberCast,
      name: StringCast,
      created_at: DateCast,
      updated_at: DateCast,
    }
  }

  get targetable_type_entity() {
    return modelEntityByName(this.targetable_type)
  }

  static config = {
    axiosApi: {
      actions: {
        readAll() {
          return requestForRoute(this, 'targets.readAll')
            .then((response) => {
              const people = _.map(response.response.data, (item) => item.nested.targetable)
              getRepo('people').save(people)
            })
        },
        readManyByIds({ ids }) {
          const data = { ids }
          return requestForRoute(this, 'targets.readManyByIds', { data })
        },
        createOne(data) {
          return requestForRoute(this, 'targets.createOne', { data } )
        },
        destroyOne({ id }) {
          const data = { id }
          return requestForRoute(this, 'targets.destroyOne', { data, config: { delete: id }})
        },
      }
    }
  }

}
