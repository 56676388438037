import _ from 'lodash'

const preloadImage = (url) => {
  var img = new Image()
  img.decoding = 'async'
  img.src = url
  return img
}

const aroundIndexes = ({ value, max }) => {
  return [
    (value - 1) < 0 ? max : value - 1,
    (value + 1) > max ? 0 : value + 1,
  ]
}

export {
  preloadImage,
  aroundIndexes,
}
