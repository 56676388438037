import _ from 'lodash'

import { digByPath } from '@/logic/utils/dig'
import { routes } from '@/logic/api/routes'

const buildFormData = (data) => {
  const formData = new FormData()
  for (const key in data) {
    formData.append(key, data[key])
  }
  return formData
}

const callMethod = ({ ctx, method, path, data, config }) => {
  // console.log('callMethod', { method, path, data, config })
  const as = config.as || 'json'
  delete config.as
  const fn = ctx[method].bind(ctx)
  const payload =
    as === 'form' ? buildFormData(data) :
    as === 'json' ? JSON.stringify(data) : data
  switch (method) {
    case 'get':
      return fn(path, config)
    case 'post':
      return fn(path, payload, config)
    case 'put':
      return fn(path, payload, config)
    case 'patch':
      return fn(path, payload, config)
    case 'delete':
      return fn(path, config)
    case 'request':
      return fn(config)
  }
}

const requestForRoute = (ctx, routeDigPath, params = {}) => {
  const data = params.data || {}
  const config = params.config || {}
  const value = digByPath(routes, routeDigPath)
  const match = value.match(/^(GET|POST|PATCH|PUT|DELETE) (.+)$/)
  const method = match[1].toLowerCase()
  const pathTemplate = match[2]
  const path = _.template(pathTemplate)(data)
  return callMethod({ ctx, method, path, data, config })
}

export {
  requestForRoute,
}
