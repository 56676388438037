import { useAuthStore } from '@/store/auth'
import { requestShouldNotSetToken } from './requestShouldNotSetToken'

const setAuthTokenOnHeaders = (headers) => {
  if (headers.get('Authorization')) { return }

  const token = useAuthStore().token
  if (token) { headers.set('Authorization', `Bearer ${token}`) }
}

const setAuthTokenOnRequest = (request) => {
  if (requestShouldNotSetToken(request)) { return }

  setAuthTokenOnHeaders(request.headers)
}

export {
  setAuthTokenOnRequest,
  setAuthTokenOnHeaders,
}
