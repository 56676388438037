<template lang='pug'>

ion-page
  ion-header
    ion-toolbar
      ion-buttons(slot='start')
        ion-back-button(text='' defaultHref='/')
      ion-title
        | Targets
  ion-content
    ion-item(
      v-for='target in targets'
      :key='target.id'
    )
      ion-label
        | {{ target.name }}

</template>

<script>

import TargetRepo from '@/repos/TargetRepo'

export default  {
  name: 'AdminTargetsListPage',
  computed: {
    ...mapRepos({
      targetRepo: TargetRepo
    }),
    targets() {
      return this.targetRepo.withAll().get()
    },
  },
}

</script>
