export default class User extends Model {
  static class_name = 'User'
  static entity = 'users'

  static fields() {
    return {
      id: this.uid(),
      email: this.string(''),
    }
  }

  static casts() {
    return {
      email: StringCast,
    }
  }

}
