<template lang='pug'>

ion-avatar(
  v-if='person?.photo?.thumb_url'
)
  img(
    :src='person.photo.thumb_url'
  )

</template>

<script setup>

import { Person } from '@/models'

const props = defineProps({
  person: {
    type: Person,
    default: () => new Person(),
  },
})

const { person } = toRefs(props)

</script>

<style scoped lang='stylus'>

ion-avatar
  :deep(img)
    border: rgba(0, 0, 0, 0.3) solid 0.125rem

</style>
