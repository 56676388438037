<template lang='pug'>

form(
  @submit.prevent='onSubmit'
)
  ion-item
    ion-input(
      v-model='params.email'
      type='email'
      :placeholder='$t("attr.email")'
      :label='$t("attr.email")'
      labelPlacement='floating'
      required
      autocomplete='new-password'
    )
  ion-item
    ion-input(
      v-model='params.password'
      type='password'
      :placeholder='$t("attr.password")'
      :label='$t("attr.password")'
      labelPlacement='floating'
      required
      autocomplete='new-password'
    )
  ion-item(lines='none')
    ion-button(
      type='submit'
      color='primary'
      size='default'
      fill='outline'
      expand='block'
    )
      | {{ $t('ui.actions.sign_up') }}
      ion-icon(
        slot='end'
        name='caret-forward'
      )

</template>

<script setup>

import {
  caretForward,
} from 'ionicons/icons'

import { auth } from '@/logic'

const params = reactive({
  email: '',
  password: '',
})

const router = useIonRouter()

const onSubmit = () => {
  auth.service
    .signUp(toValue(params))
    .then(
      () => {
        router.push({path: '/'})
      }
    )
}

addIcons({
  'caret-forward': caretForward,
})

</script>

<style scoped lang='stylus'>

ion-button
  margin-top: 2rem
  width: 100%

</style>
