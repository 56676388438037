<template lang='pug'>

ion-item-group
  ion-item-divider
ion-buttons
  ion-button(
    v-if='isAdmin'
    color='primary'
    size='default'
    fill='outline'
    shape='round'
    expand='block'
    router-link='/admin'
  )
    i-mdi-shield-account(
      slot='icon-only'
    )
  ion-button(
    v-if='isSignedIn'
    color='danger'
    size='default'
    fill='outline'
    shape='round'
    expand='block'
    @click='doSignOut'
  )
    i-mdi-logout(
      slot='icon-only'
    )

</template>

<script setup>

import { auth } from '@/logic'
import { useAuthStore } from '@/store/auth'

const router = useIonRouter()
const authStore = useAuthStore()
const { isSignedIn, isAdmin } = storeToRefs(authStore)

const doSignOut = () => {
  auth.service
    .signOut()
    .then(
      () => {
        router.navigate('/', 'forward', 'replace')
      }
    )
}

</script>

<style scoped lang='stylus'>
ion-buttons
  justify-content: center
  gap: 1 rem
  margin: 1 rem
  ion-button
    font-size: 1.6rem
    svg
      margin: 0.5rem
</style>
