import _ from 'lodash'

import { allRepos } from '@/repos'

const allRepoInstances = () => {
  return _.reduce(allRepos, (acc, value, _key) => {
    const repo = useRepo(value)
    const name = repo.model.$entity()
    acc[name] = repo
    return acc
  }, {})
}

const repoInstance = (name) => {
  return allRepoInstances()[name]
}

export {
  allRepoInstances,
  repoInstance,
}
