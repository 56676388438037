const createMessagingChannel = ({ consumer, userId }) => {
  const channel = 'MessagingChannel'
  const room = `messaging_${userId}`

  return consumer.subscriptions.create({ channel, room, }, {
    connected() {
      console.log('Connected to the channel')
    },
    disconnected() {
      console.log('Disconnected from the channel')
    },
    received(data) {
      console.log('Received data:', data)
    }
  })
}

export {
  createMessagingChannel,
}
