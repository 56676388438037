import axios from 'axios'

import { routes } from '../routes'
import { headers } from './headers'
import { setAuthTokenOnRequest } from './setAuthTokenOnRequest'

const client = axios.create({
  baseURL: routes._base,
  headers,
})

client.interceptors.request.use(
  request => {
    setAuthTokenOnRequest(request)
    return request
  },
  error => Promise.reject(error)
)

export {
  client,
}
