const routes = {
  _base: import.meta.env.VITE_API_URL,

  cable: '/cable',

  sign_up: '/users',
  sign_in: '/users/sign_in',
  sign_out: '/users/sign_out',
  auth_confirm: '/users/confirmation',

  emotions: {
    readAll: 'GET /emotions',
  },

  circle_categories: {
    readAll: 'GET /circle_categories',
  },

  circles: {
    createOne: 'POST /circles',
    readAll: 'GET /circles',
    readOne: 'GET /circles/${id}',
    updateOne: 'PATCH /circles/${id}',
    destroyOne: 'DELETE /circles/${id}',
  },

  me: {
    readMe: 'GET /me',
    readMeWithData: 'GET /me/data',
    updateMe: 'PATCH /me',
    updatePhoto: 'PUT /me/photo',
    destroyPhoto: 'DELETE /me/photo',
  },

  people: {
    readManyByIds: 'GET /people/by_ids?ids=${ids}',
    readOne: 'GET /people/${id}',
    readOneBySlug: 'GET /people/by_slug/${slug}',
  },

  targets: {
    readAll: 'GET /targets',
    readManyByIds: 'GET /targets/by_ids?ids=${ids}',
    createOne: 'POST /targets',
    destroyOne: 'DELETE /targets/${id}',
  },

  thoughts: {
    readAll: 'GET /thoughts',
    readOne: 'GET /thoughts/${id}',
    createOne: 'POST /thoughts',
    updateOne: 'PATCH /thoughts/${id}',
    destroyOne: 'DELETE /thoughts/${id}',
  },

  thoughts_summaries: {
    readAll: 'GET /thoughts_summaries',
  },

}

export {
  routes,
}
