import { AuthSignInParamsModelSchema } from './schemas/AuthSignInParamsModelSchema'

import * as validation from '@/logic/modelValidationMethods'

export default class AuthSignInParams extends Model {
  static class_name = 'AuthSignInParams'
  static entity = 'auth_sign_in_params'
  static schema = AuthSignInParamsModelSchema

  static fields() {
    return {
      email: this.string(null),
      password: this.string(null),
    }
  }

  static casts() {
    return {
      email: StringCast,
      password: StringCast,
    }
  }

  get isValid() {
    return validation.isValid.apply(this)
  }

  get errors() {
    return validation.errors.apply(this)
  }

  errorsAt(field) {
    return validation.errorsAt.apply(this, [field])
  }

}
