<template lang='pug'>

ion-page
  ion-header
    ion-toolbar(color='warning')
      ion-buttons(slot='start')
        ion-back-button(
          text=''
          defaultHref='/'
          v-if='!error.hideBackButton'
        )
      ion-title
        | Oops!
  ion-content
    .ion-padding
      .ion-text
        h1
          | {{ error.title }}
      .ion-text(
        v-if='error.message'
      ) 
        p(v-for='line in error.message.split("\\n")')
          | {{ line }}
  ion-footer
    ion-toolbar
      ion-button(
        v-if='!error.hideHomeButton'
        size='default'
        router-link='/'
        expand='block'
      )
        | Go Home

</template>

<script>

import errors from '@/data/errors.yml'

export default  {
  name: 'ErrorPage',
  setup() {
    const route = useRoute()
    const { id } = route.params
    return { id }
  },
  computed: {
    error() {
      return errors[this.id] || errors['unknown']
    },
  },
}

</script>
