import SignUpPage from '@/views/auth/SignUpPage.vue'
import SignUpByInvitationPage from '@/views/auth/SignUpByInvitationPage.vue'
import SignInPage from '@/views/auth/SignInPage.vue'
import FriendsSocialPage from '@/views/FriendsSocialPage.vue'
import FakePersonPage from '@/views/FakePersonPage.vue'

const routes = [
  {
    path: '/auth/sign_up',
    component: SignUpPage,
    meta: { requiresAuth: false },
  },
  {
    path: '/auth/sign_up/by_invitation',
    component: SignUpByInvitationPage,
    meta: { requiresAuth: false },
  },
  {
    path: '/auth/sign_in',
    component: SignInPage,
    meta: { requiresAuth: false },
  },
  {
    path: '/friends/social',
    component: FriendsSocialPage,
  },
  {
    path: '/prototype/fake_person',
    component: FakePersonPage,
  },
]

export default routes
