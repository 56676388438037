import HomePage from '@/views/HomePage.vue'
import WelcomePage from '@/views/WelcomePage.vue'
import SignOutPage from '@/views/SignOutPage.vue'
import SignUpConfirmationPage from '@/views/auth/SignUpConfirmationPage.vue'
import MainPage from '@/views/MainPage.vue'
import MePage from '@/views/MePage.vue'
import MeEditPage from '@/views/MeEditPage.vue'
import AppSettingsPage from '@/views/AppSettingsPage.vue'
import MainReceivePage from '@/views/MainReceivePage.vue'
import MainSendPage from '@/views/MainSendPage.vue'
import MainPeoplePage from '@/views/MainPeoplePage.vue'
import CirclesPage from '@/views/CirclesPage.vue'
import CircleEditPage from '@/views/CircleEditPage.vue'
import PersonPage from '@/views/PersonPage.vue'
import LegalPrivacyPolicyPage from '@/views/LegalPrivacyPolicyPage.vue'
import LegalTermsOfUsePage from '@/views/LegalTermsOfUsePage.vue'

const routes = [
  {
    path: '/',
    component: HomePage,
    meta: { requiresAuth: true },
  },
  {
    path: '/welcome',
    component: WelcomePage,
    meta: { requiresAuth: false },
  },
  {
    path: '/auth/sign_out',
    component: SignOutPage,
    meta: { requiresAuth: false },
  },
  {
    path: '/auth/confirmation/:token',
    component: SignUpConfirmationPage,
    meta: { requiresAuth: false },
  },
  {
    path: '/main',
    component: MainPage,
    meta: { requiresAuth: false },
    children: [
      {
        path: '/people',
        component: MainPeoplePage,
      },
      {
        path: '/receive',
        component: MainReceivePage,
      },
      {
        path: '/send',
        component: MainSendPage,
      },
      {
        path: '/me',
        component: MePage,
      },
      {
        path: '/me/edit',
        component: MeEditPage,
      },
      {
        path: '/settings',
        component: AppSettingsPage,
      },
      {
        path: '/circles',
        component: CirclesPage,
      },
      {
        path: '/circles/new',
        component: CircleEditPage,
        props: true,
      },
      {
        path: '/circles/:id/edit',
        component: CircleEditPage,
        props: true,
      },
      {
        path: '/@:slug',
        component: PersonPage,
        props: true,
        meta: { requiresAuth: false },
      },
    ]
  },
  {
    path: '/privacy-policy',
    component: LegalPrivacyPolicyPage,
    meta: { requiresAuth: false },
  },
  {
    path: '/terms-of-use',
    component: LegalTermsOfUsePage,
    meta: { requiresAuth: false },
  },
]

export default routes
