import _ from 'lodash'
import { createI18n } from 'vue-i18n'

import messages from '@/data/locale'

const defaultLocale = 'en'

const i18n = createI18n({
  locale: defaultLocale,
  fallbackLocale: defaultLocale,
  messages,
})

const changeLocale = (value) => {
  if (!value) { return }
  if (_.includes(i18n.global.availableLocales, value)) {
    i18n.global.locale = value
  } else {
    i18n.global.locale = defaultLocale
  }
}

const t = i18n.global.t

export {
  i18n,
  changeLocale,
  t,
}
