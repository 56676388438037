import { Person } from '@/models'
import { useAuthStore } from '@/store/auth'

export default class PersonRepo extends AxiosRepository {
  use = Person

  getMe() {
    const { user } = storeToRefs(useAuthStore())
    return this.query().withAll().find(user.value?.person_id)
  }

}
