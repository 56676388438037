<template lang='pug'>

.container
  ion-img.centered-image(
    :src='imgSrc'
  )

</template>

<script setup>

import FakePhoto from '@/logic/utils/FakePhoto'

const fakePhoto = new FakePhoto

const props = defineProps({
  id: {
    type: String,
    required: true,
  }
})

const imgSrc = computed(() => {
  return fakePhoto.url(props.id)
})

</script>

<style scoped lang='stylus'>

.container
  display: flex
  justify-content: center
  align-items: center
  height: 100%
  :deep(.centered-image)
    height: 100%
    object-fit: cover

</style>
