import AdminHomePage from '@/views/AdminHomePage.vue'
import AdminEmotionsListPage from '@/views/AdminEmotionsListPage.vue'
import AdminCircleCategoriesListPage from '@/views/AdminCircleCategoriesListPage.vue'
import AdminTargetsListPage from '@/views/AdminTargetsListPage.vue'

const routes = [
  {
    path: '/admin',
    component: AdminHomePage,
  },
  {
    path: '/admin/emotions',
    component: AdminEmotionsListPage,
  },
  {
    path: '/admin/circle_categories',
    component: AdminCircleCategoriesListPage,
  },
  {
    path: '/admin/targets',
    component: AdminTargetsListPage,
  },
]

export default routes
