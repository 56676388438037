<template lang='pug'>

ion-tabs
  ion-router-outlet
  ion-tab-bar(
    slot='bottom'
  )
    ion-tab-button(
      v-for='button in buttons'
      :key='button.tab'
      :tab='button.tab'
      :href='button.href'
      v-show='button.show'
    )
      component(
        :is='button.icon'
      )

</template>

<script setup>

import IconPeople from '~icons/mdi/people'
import IconHeartCircle from '~icons/mdi/heart-circle'
import IconHeart from '~icons/mdi/heart'

const buttons = ref([])

buttons.value = [
  {
    tab: 'people',
    href: '/people',
    icon: shallowRef(IconPeople),
    show: true,
  },
  {
    tab: 'receive',
    href: '/receive',
    icon: shallowRef(IconHeartCircle),
    show: true,
  },
  {
    tab: 'send',
    href: '/send',
    icon: shallowRef(IconHeart),
    show: true,
  },
]

</script>

<style scoped lang='stylus'>
ion-tab-button
  font-size: 160%
  &.tab-selected
    &#tab-button-people
      color: #d66
    &#tab-button-receive
      color: #f44
    &#tab-button-send
      color: #b33
</style>
