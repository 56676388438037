<template lang='pug'>

ion-page
  ion-header
    ion-toolbar
      ion-buttons(slot='end')
        NavTopMyProfileButton
      ion-title
        | WHIM
  ion-content
    PeopleTotalThoughtsFeed(
      :data='data'
    )
      template(v-slot:scroll)
        ion-infinite-scroll(
          position='top'
          threshold='1px'
          :disabled='isScrollDisabled'
          @ionInfinite='handleInfiniteScroll'
        )
          ion-infinite-scroll-content(
            loading-spinner='dots'
          )

</template>

<script setup>

import _ from 'lodash'
import { onIonViewDidEnter, onIonViewWillLeave } from '@ionic/vue'

import { getRepo } from '@/repos'
const personRepo = getRepo('people')

const person = computed(() => personRepo.getMe())
const records = computed(() => {
  return getRepo('personal_thoughts_summaries').withAll().get()
})
const data = computed(() => {
  return _.groupBy(records.value, 'created_at_date')
})

const isScrollDisabled = ref(false)

const handleInfiniteScroll = async (event) => {
  setTimeout(() => {
    event.target.complete()
    isScrollDisabled.value = true
  }, 50)
}

onIonViewDidEnter(() => {
  limitedRefresh()
    .then( () => {
      isScrollDisabled.value = false
      document.querySelector('ion-content').scrollToBottom(0)
    })
})

onIonViewWillLeave(() => {
  isScrollDisabled.value = true
})

const refresh = () => {
  return personRepo.api().readPersonalThoughtsSummaries()
}

const limitedRefresh = _.throttle(refresh, 10_000, { leading: true, trailing: false })

const doRefresh = (event) => {
  limitedRefresh()
    .then(()=> event.target.complete())
}

</script>

<style scoped lang='stylus'>

</style>
