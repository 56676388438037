class Debug {

  constructor() {
    this.store = {}
  }

  clear() {
    this.store = {}
  }

  set(key, value) {
    this.store[key] = value
  }

  get(key, ...params) {
    const value = this.store[key]
    return typeof value == 'function' ? value.apply(this, params) : value
  }

}

const debug = new Debug()

export default debug
export { debug }
