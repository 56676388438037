import { Person, Target, Emotion } from '@/models'
import { requestForRoute } from '@/logic/api/requestForRoute'

export default class Thought extends Model {
  static class_name = 'Thought'
  static entity = 'thoughts'

  static fields() {
    return {
      id: this.uid(),
      person_id: this.number(null),
      target_id: this.number(null),
      emotion_id: this.number(null),
      created_at: this.attr(null),
      updated_at: this.attr(null),
      person: this.belongsTo(Person, 'person_id'),
      target: this.belongsTo(Target, 'target_id'),
      emotion: this.belongsTo(Emotion, 'emotion_id'),
    }
  }

  static casts() {
    return {
      person_id: NumberCast,
      target_id: NumberCast,
      emotion_id: NumberCast,
      created_at: DateCast,
      updated_at: DateCast,
    }
  }

  static config = {
    axiosApi: {
      actions: {
        createOne(data) {
          return requestForRoute(this, 'thoughts.createOne', { data } )
        },
      }
    }
  }

}
