<template lang='pug'>

ion-page
  ion-header
    ion-toolbar
      ion-buttons(slot='start')
        ion-back-button(text='' defaultHref='/')
      ion-title.ion-text-capitalize
        | {{ $t('ui.pages.me_edit.title') }}
  ion-content
    ion-list(
      v-if='personRef'
    )
      ion-item
        AttrInput(
          v-model:model='personRef'
          attr='name'
        )
      ion-item
        ion-label
          | {{ $t('attr.photo') }}
        input(
          type='file'
          @change='onFileChange'
          ref='fileInput'
        )
      ion-item(
        v-if='personRef.photo?.full_url'
      )
        PersonAvatar(
          :person='personRef'
          style='width: 100px; height: 100px;'
        )
        ion-button(
          @click='doDeletePhoto'
          fill='clear'
          color='danger'
        )
          i-mdi-delete(style='font-size: 1.6rem')
    ion-button.ion-padding(
      fill='solid'
      size='default'
      expand='block'
      @click='onButtonSaveClick'
    )
      | {{ $t('ui.actions.save') }}

</template>

<script setup>

import { onIonViewWillLeave } from '@ionic/vue'

import { getRepo } from '@/repos'
import { saveToRepo } from '@/logic/models/saveToRepo'

const router = useIonRouter()
const personRepo = getRepo('people')
const personRef = computed(() => {
  return personRepo.getMe()
})

const fileInput = ref(null)

const doDeletePhoto = () => {
  personRepo.api().destroyPhoto()
}

const onFileChange = (event) => {
  const file =  event.target.files[0]
  personRepo.api().updatePhoto({ photo: file })
}

const save = () => {
  saveToRepo(personRepo, personRef, ['id', 'name'])
}

const onButtonSaveClick = () => {
  if (personRef.value.isValid) {
    save()
    router.navigate('/me', 'forward', 'replace')
  }
}

onIonViewWillLeave(() => {
  if (personRef.value.isValid) {
    save()
  }
})

</script>
