<template lang='pug'>

form(
  ref='form'
  @submit.prevent='onSubmit'
  @input='formValidate'
)
  ion-item
    AttrInput(
      v-model:model='params'
      attr='email'
      labelPlacement='floating'
      autocomplete='new-password'
    )
  ion-item
    AttrInput(
      v-model:model='params'
      attr='password'
      type='password'
      labelPlacement='floating'
      autocomplete='new-password'
    )
  ion-item(lines='none')
    ion-button(
      :disabled='formIsDisabled'
      type='submit'
      color='primary'
      size='default'
      fill='outline'
      expand='block'
    )
      | {{ $t('ui.actions.sign_in') }}
      ion-icon(
        slot='end'
        name='caret-forward'
      )

</template>

<script setup>

import {
  caretForward,
} from 'ionicons/icons'

import { auth } from '@/logic'
import { AuthSignInParams } from '@/models'

const router = useIonRouter()
const params = new AuthSignInParams
const form = ref()
const formIsDisabled = ref(true)

const onSubmit = () => {
  if (!params.isValid) return

  auth.service
    .signIn(toValue(params))
    .then(
      () => {
        formReset()
        router.push({path: '/'})
      }
    )
}

const formReset = () => {
  form.value.reset()
  params.email = undefined
  params.password = undefined
  formIsDisabled.value = true
}

const formValidate = () => {
  formIsDisabled.value = params.isValid ? false : true
}

addIcons({
  'caret-forward': caretForward,
})

</script>

<style scoped lang='stylus'>

ion-button
  margin-top: 2rem
  width: 100%

</style>
