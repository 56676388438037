import * as yup from 'yup'

const CircleModelSchema =
  yup.object({
    id: yup.string().nullable(),
    name: yup.string().required(),
    person_id: yup.number().nullable(),
    category_id: yup.number().required(),
  })

export {
  CircleModelSchema,
}
