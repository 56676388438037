const share = (params = {}) => {
  if (!navigator.share) {
    Promise.resolve()
  }

  return navigator.share(params)
}

export {
  share,
}
