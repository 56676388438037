import { client } from './client'
import { routes } from './routes'

const returnResponseData = (response) => {
  return response.data
}

const signUp = async (user) => {
  return await client
    .post(routes.sign_up, {
      user: {
        email: user.email,
        password: user.password
      }
    })
}

const signIn = async (user) => {
  return await client
    .post(routes.sign_in, {
      user: {
        email: user.email,
        password: user.password
      }
    })
}

const signOut = async () => {
  return await client
    .delete(routes.sign_out)
}

const authConfirm = async (token) => {
  const params = new URLSearchParams({
    confirmation_token: token,
  }).toString()
  return await client
    .get(routes.auth_confirm + '?' + params)
}

const me = async () => {
  return await client
    .get(routes.me)
    .then(returnResponseData)
}

const emotionsListAll = async () => {
  return await client
    .get(routes.emotions.list_all)
    .then(returnResponseData)
}

const circleCategoriesListAll = async () => {
  return await client
    .get(routes.circle_categories.list_all)
    .then(returnResponseData)
}

const circlesListAll = async () => {
  return await client
    .get(routes.circles.list_all)
    .then(returnResponseData)
}

export {
  signUp,
  signIn,
  signOut,
  authConfirm,
  me,
  emotionsListAll,
  circleCategoriesListAll,
  circlesListAll,
}
