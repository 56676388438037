import _ from 'lodash'

const saveToRepo = (repo, model, attrNames = []) => {
  const modelAttrs = model.value.$getAttributes()
  const attrs = attrNames.length == 0
    ? modelAttrs
    : _.pick(modelAttrs, attrNames)
  const attrsWithTouch = _.merge(attrs, { touchApi: true })
  return repo.save(attrsWithTouch)
}

const destroyToRepo = (repo, id) => {
  return repo.destroy(id)
}

export {
  saveToRepo,
  destroyToRepo,
}
