import { Circle, Target } from '@/models'

export default class CircleTarget extends Model {
  static class_name = 'CircleTarget'
  static entity = 'circle_targets'

  static fields() {
    return {
      id: this.uid(),
      circle_id: this.number(null),
      target_id: this.number(null),
      created_at: this.attr(null),
      updated_at: this.attr(null),
      circle: this.belongsTo(Circle, 'circle_id'),
      target: this.belongsTo(Target, 'target_id'),
    }
  }

  static casts() {
    return {
      circle_id: NumberCast,
      target_id: NumberCast,
      created_at: DateCast,
      updated_at: DateCast,
    }
  }

}
