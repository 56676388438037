import {
  allRepoInstances, repoInstance,
} from './helpers/instances'

import CircleCategoryRepo from './CircleCategoryRepo'
import CircleRepo from './CircleRepo'
import CircleTargetRepo from './CircleTargetRepo'
import EmotionRepo from './EmotionRepo'
import PersonRepo from './PersonRepo'
import PersonalThoughtsSummaryRepo from './PersonalThoughtsSummaryRepo'
import TargetRepo from './TargetRepo'
import ThoughtRepo from './ThoughtRepo'
import UserRepo from './UserRepo'

const allRepos = {
  CircleCategoryRepo,
  CircleRepo,
  CircleTargetRepo,
  EmotionRepo,
  PersonRepo,
  PersonalThoughtsSummaryRepo,
  TargetRepo,
  ThoughtRepo,
  UserRepo,
}

export {
  allRepos,
  allRepoInstances as getRepos,
  repoInstance as getRepo,

  CircleCategoryRepo,
  CircleRepo,
  CircleTargetRepo,
  EmotionRepo,
  PersonRepo,
  PersonalThoughtsSummaryRepo,
  TargetRepo,
  ThoughtRepo,
  UserRepo,
}
