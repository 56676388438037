import { sentryInit } from '@/deps/sentry'

import { IonicVue } from '@ionic/vue'

import { pinia } from '@/deps/pinia'

import { GesturePlugin } from '@vueuse/gesture'

import App from './App.vue'
import router from './router'
import registerGlobalComponents from '@/logic/utils/registerGlobalComponents'
import { i18n } from '@/logic/i18n'
import { onReady } from '@/logic/onReady'

import _ionicVueStyles from '@/deps/ionic-vue-styles'
import _vantStyles from '@/deps/vant-styles'
import _appStyles from '@/deps/app-styles'

const app = createApp(App)

sentryInit({ app, router })

app
  .use(IonicVue)
  .use(GesturePlugin)
  .use(i18n)
  .use(pinia)
  .use(router)

app.config.ignoredElements = [/^ion-/]

registerGlobalComponents(app)

import debug from '@/logic/utils/debug'
import __debugRepos from '@/logic/models/debugRepos'

import './registerServiceWorker'

router.isReady().then(() => {
  debug.set('app', () => app)
  app.mount('#app')
  if (process.env.NODE_ENV === 'development') {
    window.debug = debug
  }
  onReady()
})
