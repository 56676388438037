import { t } from '@/logic/i18n'
import { requestForRoute } from '@/logic/api/requestForRoute'
import { Circle } from '@/models'

export default class CircleCategory extends Model {
  static class_name = 'CircleCategory'
  static entity = 'circle_categories'

  static fields() {
    return {
      id: this.uid(),
      symbol: this.string(null),
      name: this.string(null),
      circles: this.hasMany(Circle, 'category_id'),
    }
  }

  static casts() {
    return {
      symbol: StringCast,
      name: StringCast,
    }
  }

  static config = {
    axiosApi: {
      actions: {
        readAll() {
          return requestForRoute(this, 'circle_categories.readAll')
        },
      }
    }
  }

  get nameLocalized() {
    return t(`collections.circle_categories.name.${this.symbol}`)
  }

}
