<template lang='pug'>

.items

  slot(name='scroll')

  .item(
    v-for='(items, date) in data'
  )
    van-divider.date
      | {{ recentDate(date) }}
    .people
      .person(
        v-for='item in items'
      )
        van-badge.person-avatar(
          :class='{ "mode-emotions": isModeExtended(item) }'
          :content='totalCounter(item)'
          :max='99'
          color='var(--ion-color-primary)'
        )
          PersonAvatar(
            :person='findPerson(item.person_id)'
            style='width: 3rem; height: 3rem;'
            @click='toggleMode(item)'
          )
        .emotions-counters(
          v-if='isModeExtended(item)'
        )
          EmotionWithCounter.emotions-counter(
            v-for='(emotion_counter, emotion_symbol) in item.emotions_count'
            :symbol='emotion_symbol'
            :counter='emotion_counter'
          )

</template>

<script setup>

import { recentDate } from '@/logic/time'

const props = defineProps({
  data: {
    type: Object,
    required: true,
  },
})

const { data } = toRefs(props)

const recordModeExtended = ref({})

const getModeExtended = (record) => {
  const defaultValue = 'person'
  const intialValue = record.mode
  const storedValue = recordModeExtended.value[[record.created_at_date, record.person_id]]
  return storedValue || intialValue || defaultValue
}

const setModeExtended = (record, value) => {
  recordModeExtended.value[[record.created_at_date, record.person_id]] = value
}

const isModeExtended = (record) => {
  return getModeExtended(record) == 'emotions'
}

const toggleMode = (record) => {
  const values = ['person', 'emotions']
  const value = getModeExtended(record)
  const index = values.indexOf(value)
  const newValue = values[(index + 1) % values.length]
  setModeExtended(record, newValue)
}

const totalCounter = (record) => {
  return isModeExtended(record) ? null : record.thoughts_count
}

import { getRepo } from '@/repos'

const personRepo = getRepo('people')

const findPerson = (id) => {
  return personRepo.find(id)
}

</script>

<style scoped lang='stylus'>

.items
  display: flex
  flex-direction: column
  justify-content: flex-end
  min-height: calc(100vh - 8rem)
  margin-bottom: 1rem

  .item
    display: flex
    flex-direction: column

  .people
    display: flex
    gap: 1rem
    flex-wrap: wrap
    padding: 0 0.75rem

  .person
    display: flex

    .person-avatar
      display: flex
      user-select: none

  .emotions-counters
    display: flex
    flex-wrap: wrap
    gap: 0.125rem
    align-items: center
    margin-right: 1rem

    .emotions-counter
      user-select: none

</style>
