import { createRouter, createWebHistory } from '@ionic/vue-router';
import { useAuthStore } from '@/store/auth'
import routes from '@/router/routes'

const homeRoute = {
  undefined: '/',
  signedIn: '/send',
  signedOut: '/welcome',
}

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes
})

router.beforeEach((to) => {
  if (to.meta.requiresAuth == false) {
    return
  } else {
    const authStore = useAuthStore()
    if (authStore.isSignedIn) {
      if (to.path == homeRoute.undefined) {
        return homeRoute.signedIn
      } else {
        return
      }
    }
    else {
      return homeRoute.signedOut
    }
  }
})

export default router
