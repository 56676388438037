import prototypes from './prototypes'
import admin from './admin'
import errors from './errors'
import main from './main'
import other from './other'

const routes = [
  ...prototypes,
  ...admin,
  ...errors,
  ...main,
  ...other,
]

export default routes
