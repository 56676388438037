import _ from 'lodash'

// when going offline, wait for 3 seconds and then redirect to offline error page
// when going online, cancel going offline if started and instantly redirect back
// if going offline/online within 3 seconds, handle by offlineHandler by throttling/debouncing/canceling/flushing

const offlineThreshold = 5000
const onlineThreshold = 500
const errorPagePath = '/error/offline'

const initOfflineHandler = (ctx, { router }) => {
  const redirectToOfflineErrorPage = () => {
    router.push({ path: errorPagePath })
  }

  const redirectToHomePage = () => {
    router.push({ path: '/' })
  }

  const redirectBack = () => {
    router.back()
  }

  const offlineHandler = _.debounce( () => {
    onlineHandler.cancel()
    if (location.path == errorPagePath) { return }
    redirectToOfflineErrorPage()
  }, offlineThreshold)

  const onlineHandler = _.debounce( () => {
    offlineHandler.cancel()
    if (location.path == errorPagePath) { return }
    if (router.canGoBack()) {
      redirectBack()
    } else {
      redirectToHomePage()
    }
  }, onlineThreshold)

  ctx.addEventListener('offline', offlineHandler)
  ctx.addEventListener('online', onlineHandler)
}

export {
  initOfflineHandler,
}
