const importedEnv = import.meta.env
const prefix = 'VITE_'
const reCaptureKey = new RegExp(`^${prefix}(.*)`)

const env =
  Object.keys(importedEnv)
    .reduce((memo, key) => {
      const match = key.match(reCaptureKey)
      if (match) {
        const newKey = match[1]
        memo[newKey] = importedEnv[key]
      }
      return memo
    }, {})

export {
  env,
  importedEnv,
}
