<template lang='pug'>

ion-page
  ion-content

</template>

<script setup>

import { onIonViewWillEnter } from '@ionic/vue'

import { auth } from '@/logic'

const router = useIonRouter()

onIonViewWillEnter(() => {
  auth.service
    .signOut()
    .then(
      () => {
        router.navigate('/', 'forward', 'replace')
      }
    )
})

</script>
