<template lang='pug'>

swiper(
  v-bind='swiperConfig'
  @swiper='onSwiperInit'
  @slideChange='onSlideChange'
)
  swiper-slide(
    v-for='item in collection.items'
    :key='item.id'
    :data-id='item.id'
  )
    ion-text
      p
        | {{ item.name }}

</template>

<script setup>

import {
  Swiper,
  SwiperSlide,
  Navigation,
} from '@/deps/swiper'

const collection = defineModel({
  type: Object,
  default: () => {
    return {
      items: [],
      index: 0,
      current: {},
    }
  },
})

const swiperRef = ref()

const swiperConfig = ref({
  modules: [
    Navigation,
  ],
  navigation: true,
})

const onSwiperInit = (instance) => {
  swiperRef.value = instance
}

const onSlideChange = (event) => {
  collection.value.index = event.activeIndex
}

const rewindSwiper = (index) => {
  swiperRef.value.slideTo(index, 0, false)
}

watch(() => collection.value.items, (newValue, _oldValue) => {
  rewindSwiper(collection.value.index)
})

watch(() => collection.value.index, (newValue, _oldValue) => {
  rewindSwiper(newValue)
})

</script>

<style scoped lang='stylus'>

:deep(.swiper-button-next),
:deep(.swiper-button-prev)
  &:after
    font-size: 1rem
    font-weight: bold
    color: var(--ion-color-primary)

</style>
