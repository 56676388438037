<template lang='pug'>

ion-button(
  fill='clear'
  @click='changeRoute'
)
  ion-icon(
    :name='icon'
  )

</template>

<script setup>

import {
  createOutline,
  addCircleOutline,
} from 'ionicons/icons'

const props = defineProps({
  path: {
    type: String,
    required: true,
  },
  icon: {
    type: String,
    required: true,
  },
})

const { path, icon } = toRefs(props)

const router = useIonRouter()

const changeRoute = () => {
  router.push({path})
}

addIcons({
  'create-outline': createOutline,
  'add-circle-outline': addCircleOutline,
})

</script>
