<template lang='pug'>

ion-page
  ion-header
    ion-toolbar
      ion-buttons(slot='start')
        ion-back-button(text='' defaultHref='/')
      ion-title
        | Admin

  ion-content
    ion-list
      ion-item(
        v-for='button in buttons'
        :key='button.name'
        :button='true'
        :router-link='button.path'
      )
        ion-label
          | {{ button.name }}

</template>

<script setup>

import { getRepo } from '@/repos'

const personRepo = getRepo('people')
const me = computed(() => personRepo.getMe())
const myProfilePath = computed(() => {
  return `/@${me.value?.slug}`
})

const buttons = reactive([
  { name: 'Emotions', path: '/admin/emotions' },
  { name: 'Circle Categories', path: '/admin/circle_categories' },
  { name: 'Targets', path: '/admin/targets' },
  { name: 'Fake Person', path: '/prototype/fake_person' },
  { name: 'My Profile', path: myProfilePath},
])

</script>
