import _ from 'lodash'

function isValid(data = undefined) {
  data = _.isObject(data) ? data : this.$getAttributes()
  return this.constructor.schema.isValidSync(data)
}

function willBeValid(data) {
  return isValid.apply(this, [data])
}

function errors() {
  return _
    .chain(this.constructor.schema.fields)
    .reduce((acc, _field, key) => {
      acc[key] = this.errorsAt(key)
      return acc
    }, {})
    .value()
}

function errorsAt(field, data = undefined) {
  data = _.isObject(data) ? data : this.$getAttributes()
  const path = field
  try {
    this.constructor.schema.validateSyncAt(path, data)
    return []
  } catch (error) {
    if (error.name === 'ValidationError') {
      return error.errors
    }
    if (error.message.startsWith('The schema does not contain the path')) {
      return []
    }
    throw error
  }
}

function isLocal() {
  return typeof this.id === 'string'
}

export {
  isValid,
  willBeValid,
  errors,
  errorsAt,
  isLocal,
}
