import { alertController } from '@ionic/vue'
import { alertParamsForAxiosError } from '@/logic/utils/alertParamsForAxiosError'

const presentAlert = async (error) => {
  const params = alertParamsForAxiosError(error)
  const alert = await alertController.create(params)
  await alert.present()
}

export {
  presentAlert,
}
