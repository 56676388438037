import _ from 'lodash'

import { routes } from '@/logic/api/routes'

const requestsWithNoAuth = [
  { method: 'post', path: routes.sign_in },
  { method: 'post', path: routes.sign_up },
]

const requestShouldNotSetToken = (request) => {
  return _.some(requestsWithNoAuth, {
    method: request.method,
    path: request.url
  })
}

export {
  requestShouldNotSetToken,
}
