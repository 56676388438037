import { api } from '@/logic'
import { useAuthStore } from '@/store/auth'
import { presentAlert } from '@/logic/utils/presentAlert'

const tokenParseFromResponse = (response) => {
  return response.headers.authorization.match(/^Bearer (.*)$/)[1]
}

const store = () => {
  return useAuthStore()
}

const signOut = () => {
  store().signOut()
  api.service.signOut()
  return Promise.resolve()
}

const signIn = (authParams) => {
  return api.service
    .signIn(authParams)
    .then(
      (response) => {
        const user = response.data
        const token = tokenParseFromResponse(response)
        store().signIn({ token, user })
        return Promise.resolve(user)
      },
      (error) => {
        store().signOut()
        presentAlert(error)
        return Promise.reject(error)
      }
    )
}

const signUp = (authParams) => {
  return api.service
    .signUp(authParams)
    .then(
      (response) => {
        const user = response.data
        const token = tokenParseFromResponse(response)
        store().signUp({ token, user })
        return Promise.resolve(user)
      },
      (error) => {
        store().signOut()
        presentAlert(error)
        return Promise.reject(error)
      }
    )
}

const authConfirm = (token) => {
  return api.service
    .authConfirm(token)
    .then(
      (response) => {
        const user = response.data
        const token = tokenParseFromResponse(response)
        store().signIn({ token, user })
        return Promise.resolve(user)
      },
      (error) => {
        presentAlert(error)
        return Promise.reject(error)
      }
    )
}

export {
  signOut,
  signIn,
  signUp,
  authConfirm,
}
