<template lang='pug'>
ion-app
  ion-router-outlet
</template>

<script setup>

import { initOfflineHandler } from '@/logic/utils/offline'

const router = useIonRouter()

initOfflineHandler(window, { router: router })

</script>
