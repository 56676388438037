<template lang='pug'>

ion-page
  ion-header
    ion-toolbar
      ion-buttons(slot='start')
        ion-back-button(text='' defaultHref='/')
      ion-title
        | Emotions
  ion-content
    ion-list
      ion-item(
        v-for='emotion in emotions'
        :key='emotion.id'
      )
        ion-label
          | {{ emotion.emoji }}
          | {{ emotion.nameLocalized }}

</template>

<script>

import EmotionRepo from '@/repos/EmotionRepo'

export default  {
  name: 'AdminEmotionsListPage',
  computed: {
    ...mapRepos({
      emotionRepo: EmotionRepo
    }),
    emotions() {
      return this.emotionRepo.withAll().get()
    },
  },
}

</script>
