import ErrorPage from '@/views/errors/ErrorPage.vue'

const routes = [
  {
    path: '/error/:id',
    component: ErrorPage,
    meta: { requiresAuth: false },
  },
]

export default routes
