<template lang='pug'>

ion-page
  ion-header
    ion-toolbar
      ion-buttons(slot='start')
        ion-back-button(text='' defaultHref='/')
      ion-title
        | Registration Confirmation
  ion-content

</template>

<script setup>

import { onIonViewDidEnter } from '@ionic/vue'

import { auth } from '@/logic'

const router = useIonRouter()

onIonViewDidEnter(() => {
  const token = useRoute().params.token
  auth.service
    .authConfirm(token)
    .then(() => {
      router.push({path: '/'})
    })
})

</script>
