const dig = (data, keys = []) => {
  return keys.reduce((acc, key) => acc?.[key], data) ?? undefined
}

const digByPath = (data, path = '') => {
  return dig(data, path.split('.'))
}

export {
  dig,
  digByPath,
}
