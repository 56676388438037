<template lang='pug'>

ion-page
  ion-header
    ion-toolbar
      ion-buttons(slot='end')
        NavTopMyProfileButton
      ion-title
        | WHIM
  ion-content
    ion-progress-bar.again(
      v-if='!isPermittedToThinkAgain'
      :value='progress'
      color='success'
    )
    .container
      .cell.cell-photo.ion-padding
        .wrapper(
          ref='personTargetWrapper'
          :class='{ "ion-activatable": isPermittedToThinkAgain }'
          @click='doThinkThrottled'
        )
          ion-ripple-effect
          ion-text.ago
            | {{ lastThoughtCreatedAtAgo }}
          PersonTargetWithPhoto(
            :id='currentPersonId'
          )
      .cell.cell-person-swiper
        ion-button(
          v-if='people.items.length === 0 && circles.items.length > 0'
          size='small'
          @click='goToNewTarget'
        )
          | {{ $t('ui.actions.add') }}
        ThinCollectionSwiper(
          v-if='people.items.length > 0'
          v-model='people'
        )
      .cell.cell-circle-swiper(style='display: none')
        ion-button(
          v-if='circles.items.length === 0'
          size='small'
          @click='goToNewCircle'
        )
          | {{ $t('ui.actions.add') }}
        ThinCollectionSwiper(
          v-if='circles.items.length > 0'
          v-model='circles'
          @tap='goToCircles'
        )

</template>

<script setup>

import _ from 'lodash'
import { onIonViewDidEnter, onIonViewDidLeave } from '@ionic/vue'
import { useDrag } from '@vueuse/gesture'

import { timeAgo } from '@/logic/time'
import { getRepo } from '@/repos'
import { preloadImage, aroundIndexes } from '@/logic/utils/preloadImage'

const intervalBeforeThinkAgain = 60 * 1000

const router = useIonRouter()

const intervals = {
  now: {
    tick: ref(0),
    duration: 10 * 1000,
  },
  progress: {
    tick: ref(0),
    duration: 1 * 1000,
  },
}

const personRepo = getRepo('people')
const emotionRepo = getRepo('emotions')
const circleRepo = getRepo('circles')
const targetRepo = getRepo('targets')
const thoughtRepo = getRepo('thoughts')

const me = computed(() => personRepo.getMe())
const emotion = computed(() => emotionRepo.where('symbol', 'none').first())

const circlesCollection = computed(() => circleRepo.withAll().get())
const targetsCollection = computed(() => targetRepo.withAll().get())

const circles = ref({
  items: circlesCollection,
  index: ref(0),
  current: computed(() => circles.value.items[circles.value.index]),
})

const people = ref({
  items: targetsCollection,
  index: ref(0),
  current: computed(() => people.value.items[people.value.index]),
})

const currentPersonId = computed(() => people.value.current?.id)

const personTargetWrapper = ref(null)

const lastThought = computed(() => {
  if (!me.value?.id || !people.value?.current?.id) { return null }

  return thoughtRepo
    .where('person_id', me.value.id)
    .where('target_id', people.value.current.id)
    .orderBy('created_at', 'desc')
    .first()
})

const lastThoughtCreatedAtAgo = computed(() => {
  intervals.now.tick.value
  const timestamp = lastThought.value?.created_at
  if (timestamp) {
    return timeAgo(timestamp)
  }
})

const isPermittedToThinkAgain = computed( () => {
  intervals.now.tick.value
  const timestamp = lastThought.value?.created_at
  return timestamp === undefined ||
    timestamp < Date.now() - intervalBeforeThinkAgain
})

const progress = computed(() => {
  intervals.progress.tick.value
  if (isPermittedToThinkAgain.value) {
    return 0
  } else {
    const timestamp = lastThought.value?.created_at
    return 1 - (Date.now() - timestamp) / intervalBeforeThinkAgain
  }
})

const goToCircles = (event) => {
  router.push({path: '/circles'})
}

const goToNewCircle = (event) => {
  router.push({path: '/circles/new'})
}

const goToNewTarget = (event) => {
  router.push({path: '/people'})
}

useDrag(
  ({ swipe }) => {
    const noSwipe = swipe[0] == 0
    if (noSwipe) { return true }

    const swipeRight = swipe[0] > 0
    const swipeLeft = swipe[0] < 0
    if (swipeLeft) {
      swipeTo(people, 'next')
    }
    if (swipeRight) {
      swipeTo(people, 'previous')
    }
    people.value.index
    people.value.current
  }, {
    domTarget: personTargetWrapper,
    useTouch: true,
    filterTaps: true,
    delay: false,
    swipeDistance: 10,
    swipeVelocity: 0.1,
    swipeDuration: 1000,
  }
)

const swipeTo = (ref, direction) => {
  const index = ref.value.index
  const total = ref.value.items.length
  if (direction === 'next') {
    if (index >= total - 1) {
      ref.value.index = 0
    } else {
      ref.value.index++
    }
  } else if (direction === 'previous') {
    if (index === 0) {
      ref.value.index = total - 1
    } else {
      ref.value.index--
    }
  }
}

watch(() => circles.value.index, (newValue, _oldValue) => {
  // people.value.items = circles.value.current?.people || ref([])
  people.value.index = 0
  people.value.current
})

const preloadBothAroundPeoplePhotos = (newValue) => {
  aroundIndexes({
    value: newValue,
    max: people.value.items.length - 1,
  }).forEach((index) => {
    const target = people.value.items[index]
    const person = target?.targetable
    const url = person?.photo?.full_url
    if (url) {
      preloadImage(url)
    }
  })
}

watch(() => people.value.index, (newValue, _oldValue) => {
  preloadBothAroundPeoplePhotos(newValue)
  people.value.current
})

watch(() => people.value.items, (_newValue, _oldValue) => {
  preloadBothAroundPeoplePhotos(people.value.index)
  people.value.current
})

const doThink = () => {
  if (isPermittedToThinkAgain.value) {
    const attrs = {
      target_id: people.value.current.id,
      emotion_id: emotion.value.id,
    }
    thoughtRepo.api().createOne(attrs)
  }
}

const doThinkThrottled = _.throttle(doThink.bind(this), 1000, { leading: true, trailing: false })

onIonViewDidEnter(() => {
  _.forIn(intervals, (interval) => {
    interval.interval = setInterval(() => {
      interval.tick.value++
    }, interval.duration)
  })
})

onIonViewDidLeave(() => {
  _.forIn(intervals, (interval) => {
    clearInterval(interval.interval)
  })
})

</script>

<style scoped lang='stylus'>

.container
  display: flex
  flex-direction: column
  justify-content: end
  height: 100%

.cell
  flex-grow: auto
  text-align: center
  &.cell-photo
    flex-grow: 1
    height: 1%
  &.cell-person-swiper
    border-top: 1px solid var(--ion-color-light)
    color: var(--ion-color-primary)
  &.cell-circle-swiper
    border-top: 1px solid var(--ion-color-light)
    color: var(--ion-color-primary)

.cell-circle-swiper,
.cell-person-swiper
  ion-button
    margin: 1rem 0

.wrapper
  position: relative
  overflow: hidden
  height: 100%

.ago
  position: absolute
  top: 0
  right: 0
  padding: 0.25rem 0.5rem
  background: rgba(0, 0, 0, 0.5)
  color: white
  border-radius: 0 0 0 0.25rem
  &:empty
    display: none

.again
  position: absolute
  z-index: 1
  top: 0
  left: 0
  width: 100%
  height: 0.25rem
  background: rgba(0, 0, 0, 0.5)
  &::part(progress)
    transition: transform 1s linear
  &::part(track)
    display: none

</style>
