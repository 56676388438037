<template lang='pug'>

ion-page
  ion-header
    ion-toolbar
      ion-buttons(slot='end')
        NavTopMyProfileButton
      ion-title
        | WHIM
  ion-content
    ion-refresher(
      slot='fixed'
      @ion-refresh='doRefresh($event)'
    )
      ion-refresher-content
    ion-segment(
      v-model='segmentRef'
      @ionChange='segmentRef = $event.detail.value'
    )
      ion-segment-button(
        v-for='segment in segments'
        :key='segment.key'
        :value='segment.key'
      )
        | {{ segment.name }}
    EmptyState(
      v-if='people.length === 0'
      name='People'
    )
    van-swipe-cell(
      v-for='(person, index) in people'
      :key='person.id'
    )
      ion-item(
        @click='$router.push(`/@${person?.slug}`)'
      )
        ion-label
          | {{ person?.name }}
        PersonAvatar(
          slot='start'
          :person='people[index]'
          style='width: 3rem; height: 3rem;'
        )
      template(#left)
        van-button(
          square
          type='primary'
          :text='$t("ui.actions.edit")'
        )
      template(#right)
        van-button(
          square
          type='danger'
          :text='$t("ui.actions.delete")'
          @click='askToDestroyTarget(person)'
        )

</template>

<script setup>

import { getRepo } from '@/repos'
import { t } from '@/logic/i18n'
import { confirmToRemove } from '@/logic/utils/confirmations'

const segments = computed(() => [
  {
    key: 'target_people',
    name: t('ui.pages.people.segments.target_people'),
  },
  {
    key: 'friends',
    name: t('ui.pages.people.segments.friends'),
  },
])

const segmentRef = ref('target_people')

const targetRepo = getRepo('targets')
const personRepo = getRepo('people')
const me = computed(() => personRepo.getMe())

const people = computed(() => {
  const collections = me.value?.collections?.ids
  const ids = collections ? collections[segmentRef.value] || [] : []
  return personRepo.withAll().where('id', ids).get()
})

const doRefresh = (event) => {
  personRepo.api().readMeWithData()
    .then(() => event.target.complete())
}

const doDestroyTarget = (person) => {
  const target = targetRepo
    .where('targetable_type', 'Person')
    .where('targetable_id', person.id)
    .first()
  targetRepo.api().destroyOne({ id: target.id })
    .then(() => {
      personRepo.api().readMeWithData()
    })
}

const askToDestroyTarget = (person) => {
  confirmToRemove()
    .then(() => {
      doDestroyTarget(person)
    })
}

</script>

<style scoped lang='stylus'>

.van-swipe-cell
  .van-button
    height: 100%

</style>
