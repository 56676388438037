<template lang='pug'>

ion-page
  ion-header
    ion-toolbar
      ion-buttons(slot='start')
        ion-back-button(text='' defaultHref='/')
      ion-title
        | Circle Categories
  ion-content
    ion-list
      ion-item(
        v-for='circle_category in circle_categories'
        :key='circle_category.id'
      )
        ion-label
          | {{ circle_category.nameLocalized }}

</template>

<script>

import CircleCategoryRepo from '@/repos/CircleCategoryRepo'

export default  {
  name: 'AdminCircleCategoriesListPage',
  computed: {
    ...mapRepos({
      circleCategoryRepo: CircleCategoryRepo,
    }),
    circle_categories() {
      return this.circleCategoryRepo.withAll().get()
    },
  },
}

</script>
