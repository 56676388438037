import { createConsumer as originalCreateConsumer } from '@rails/actioncable'

import { routes } from '@/logic/api/routes'

const createUrl = ({ token }) => {
  return routes._base + routes.cable + '?token=' + token
}

const createConsumer = ({ token }) => {
  const url = createUrl({ token })
  return originalCreateConsumer(url)
}

export {
  createConsumer,
}
