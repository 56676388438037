<template lang='pug'>

ion-img(
  v-if='url'
  :src='url'
)

</template>

<script setup>

import { PersonRepo } from '@/repos'

const props = defineProps({
  id: {
    type: Number,
  },
})

const { id } = toRefs(props)

const personRepo = useRepo(PersonRepo)

const url = computed(() => {
  return personRepo.find(id.value)?.photo?.full_url
})

</script>

<style scoped lang='stylus'>

ion-img
  height: 100%
  overflow: hidden
  border-radius: 0.5rem

  &::part(image)
    object-fit: cover
    object-position: center

</style>
