<template lang='pug'>

ion-page
  ion-header
    ion-toolbar
      ion-buttons(slot='start')
        ion-back-button(text='' defaultHref='/')
      ion-title.ion-text-capitalize
        | {{ pageTitle }}
  ion-content
    ion-list(
      v-if='circleRef'
    )
      ion-item
        AttrInput(
          v-model:model='circleRef'
          attr='name'
          ref='firstInput'
        )
      ion-item
        ion-select(
          v-model='circleRef.category_id'
          :label='$t("attr.category")'
        )
          ion-select-option(
            v-for='category in circleCategories'
            :key='category.id'
            :value='category.id'
          )
            | {{ category.nameLocalized }}
    ion-button.ion-padding(
      size='default'
      expand='block'
      @click='onButtonSaveClick'
    )
      | {{ $t('ui.actions.save') }}

</template>

<script setup>

import _ from 'lodash'
import { onIonViewWillLeave, onIonViewDidEnter } from '@ionic/vue'

import { t } from '@/logic/i18n'
import { getRepo } from '@/repos'
import { saveToRepo } from '@/logic/models/saveToRepo'

const router = useIonRouter()
const { id } = useRoute().params
const circleRepo = getRepo('circles')
const circleCategoryRepo = getRepo('circle_categories')
const firstInput = ref('')

const circleCategories = computed(() => {
  return circleCategoryRepo.withAll().get()
})

const circleRef = computed(() => {
  if (id) {
    return circleRepo.where('id', id).withAll().first()
  } else {
    return circleRepo.make({
      category_id: _.find(circleCategories.value, { symbol: 'none' })?.id,
    })
  }
})

const pageTitle = computed(() => {
  return circleRef.value?.isLocal
    ? t('ui.pages.edit_circle.title.new_circle')
    : t('ui.pages.edit_circle.title.edit_circle')
})

const save = () => {
  saveToRepo(circleRepo, circleRef, ['id', 'name', 'category_id'])
}

const onButtonSaveClick = () => {
  if (circleRef.value.isValid) {
    save()
    router.navigate('/circles', 'forward', 'replace')
  }
}

onIonViewDidEnter(() => {
  if (circleRef.value?.isLocal) {
    firstInput.value.$el.setFocus()
  }
})

onIonViewWillLeave(() => {
  if (circleRef.value.isValid) {
    save()
  }
})

</script>
