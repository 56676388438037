import * as yup from 'yup'

const AuthSignInParamsModelSchema =
  yup.object({
    email: yup.string().email().required(),
    password: yup.string().min(3).required(),
  })

export {
  AuthSignInParamsModelSchema,
}
